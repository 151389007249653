import React, { useState, useEffect } from "react";
import { getRequest, delRequest } from "../../axiosRoutes/index";
import {
    Button,
    Box,
    CircularProgress,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import AddBankDetails from "./addBankDetails";
import SideNavBar from "../sidebar/SideNav";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";

const Clients = () => {
    const [bankDetails, setBankDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [updateId, setUpdateId] = useState(null);

    const fetchBankDetails = async () => {
        const access = sessionStorage.getItem("access");
        try {
            const response = await getRequest("admin/bank/details", {
                headers: {
                    Authorization: `Bearer ${access}`,
                },
            });
            setBankDetails(response);
        } catch (error) {
            console.error("Error fetching Bank Details:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBankDetails();
    }, []);

    const handleDelete = async () => {
        try {
            const access = sessionStorage.getItem("access");
            const response = await delRequest(`admin/bank/${deleteId}`, access);
            fetchBankDetails();
            handleClose();
            toast.success(response, toastConfig);
        } catch (error) {
            toast.error(error.message, toastConfig);
        }
    };

    const handleOpen = () => {
        setOpenModal(true);
    };

    const handleClickOpen = (id) => {
        setDeleteId(id);
        setOpen(true);
    };

    const selectClient = (id) => {
        setUpdateId(id);
        handleOpen();
    };

    const handleClose = () => {
        setOpenModal(false);
        setOpen(false);
        fetchBankDetails();
        setDeleteId(null);
        setUpdateId(null);
    };

    if (loading) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
            >
                <CircularProgress size={50} sx={{ color: "#FF5722" }} />
                <Typography variant="h6" sx={{ marginTop: "18px", color: "#fff" }}>
                    Loading...
                </Typography>
            </Box>
        );
    }

    return (
        <div className="app-container" style={{ color: "#fff", display: "flex" }}>
            <ToastContainer />
            <SideNavBar />
            <div style={{ flex: 1, margin: "0.5rem" }}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    padding="1rem"
                >
                    <Grid item>
                        <h1>Bank Details</h1>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={handleOpen}
                            sx={{
                                backgroundColor: "#fff",
                                color: "black",
                                "&:hover": {
                                    backgroundColor: "#e1e1e1",
                                },
                            }}
                        >
                            Add Bank Details
                        </Button>
                    </Grid>
                </Grid>
                {bankDetails.length === 0 ? (
                    <Typography variant="h6" sx={{ color: "#fff", textAlign: "center" }}>
                        No Data Yet
                    </Typography>
                ) : (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            color: "#fff",
                                            textAlign: "center",
                                            border: "1px solid #fff",
                                        }}
                                    >
                                        ID
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            color: "#fff",
                                            textAlign: "center",
                                            border: "1px solid #fff",
                                        }}
                                    >
                                        Account Title
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            color: "#fff",
                                            textAlign: "center",
                                            border: "1px solid #fff",
                                        }}
                                    >
                                        Details
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            color: "#fff",
                                            textAlign: "center",
                                            border: "1px solid #fff",
                                        }}
                                    >
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bankDetails.map((bank, id) => (
                                    <TableRow key={id}>
                                        <TableCell
                                            style={{
                                                color: "#fff",
                                                textAlign: "center",
                                                border: "1px solid #fff",
                                            }}
                                        >
                                            {id + 1}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color: "#fff",
                                                textAlign: "center",
                                                border: "1px solid #fff",
                                            }}
                                        >
                                            {bank.title}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                color: "#fff",
                                                textAlign: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                                border: "1px solid #fff",
                                            }}
                                        >
                                            <ul style={{ textAlign: "left" }}>{bank.details.split(',').map((detail, index) => (
                                                <li key={index}>{detail.trim()}</li>
                                            ))}</ul>

                                        </TableCell>

                                        <TableCell
                                            style={{
                                                color: "#fff",
                                                textAlign: "center",
                                                border: "1px solid #fff",
                                            }}
                                        >
                                            <IconButton
                                                style={{
                                                    color: "#fff",
                                                    backgroundColor: "#4CAF50",
                                                    margin: 2,
                                                }}
                                                aria-label="edit"
                                                size="small"
                                                onClick={() => selectClient(bank._id)}
                                            >
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton
                                                style={{
                                                    color: "#fff",
                                                    backgroundColor: "#F44336",
                                                    margin: 2,
                                                }}
                                                aria-label="delete"
                                                size="small"
                                                onClick={() => handleClickOpen(bank._id)}
                                            >
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <Dialog
                    open={open}
                    sx={{
                        "& .MuiPaper-root": {
                            backgroundColor: "#212121",
                            color: "#fff",
                            padding: "15px",
                        },
                        "& .MuiDialogTitle-root": {
                            padding: "5px",
                        },
                        "& .MuiDialogContentText-root": {
                            color: "#fff",
                        },
                        "& .MuiDialogContent-root": {
                            padding: "5px",
                        },
                        "& .MuiDialogActions-root": {
                            padding: "5px",
                        },
                    }}
                >
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you Sure You Want to Delete this Client?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            sx={{
                                backgroundColor: "#A6A6A6",
                                color: "#fff",
                                "&:hover": { backgroundColor: "#B8B8B8" },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDelete}
                            sx={{
                                backgroundColor: "#E5622D",
                                color: "#fff",
                                "&:hover": { backgroundColor: "#D0521B" },
                            }}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <AddBankDetails
                    open={openModal}
                    handleClose={handleClose}
                    bankId={updateId}
                />
            </div>
        </div>
    );
};

export default Clients;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { getRequest, delRequest, postRequest } from "../../axiosRoutes/index";
import {
  Button,
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Checkbox,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddInvoice from "./AddInvoice";
import SideNavBar from "../sidebar/SideNav";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";

const Invoices = () => {
  const [invoiceStatus, setInvoiceStatus] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bankData, setBankData] = useState([]);
  const [invoiceId, setInvoiceId] = useState(null);
  const [bankDetail, setBankDetail] = useState({
    note: "",
    title: "",
    details: ""
  });
  const [openBank, setOpenBank] = useState(false);
  const [loadingMail, setLoadingMail] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [updateId, setUpdateId] = useState(null);

  const fetchStatus = async () => {
    try {
      const response = await getRequest("filter/invoice");
      const statusArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setInvoiceStatus(statusArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  const fetchInvoices = async () => {
    const access = sessionStorage.getItem("access");
    try {
      const response = await getRequest("invoice", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      const formattedInvoices = response.map((invoice) => {
        if (invoice.date) {
          const date = new Date(invoice.date);
          invoice.date = date.toLocaleDateString("en-GB");
        }
        const statusObj = invoiceStatus.find(
          (status) => status.value === invoice.status
        );
        invoice.status = statusObj ? statusObj.label : invoice.status;
        return invoice;
      });
      setInvoices(formattedInvoices);
    } catch (error) {
      console.error("Error Fetching Invoices:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchStatus();
      fetchInvoices();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (invoiceStatus.length > 0) {
      fetchInvoices();
    }
  }, [invoiceStatus]);

  const handleDelete = async () => {
    try {
      const access = sessionStorage.getItem("access");
      const response = await delRequest(`invoice/${deleteId}`, access);
      fetchInvoices();
      handleClose();
      toast.success(response, toastConfig);
    } catch (error) {
      toast.error(error.message, toastConfig);
    }
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClickOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleSelectChange = (event) => {
    const selectedBankId = event.target.value;

    const selectedBank = bankData.find((bank) => bank._id === selectedBankId);

    if (selectedBank) {
      setBankDetail({
        title: selectedBank.title,
        details: selectedBank.details,
      });
    }
  };

  const fetchBankDetails = async () => {
    const access = sessionStorage.getItem("access");
    try {
      const response = await getRequest("admin/bank/details", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      setBankData(response);
    } catch (error) {
      console.error("Error fetching Bank Details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBankDetails();
  }, []);

  const handleBankOpen = (id) => {
    setInvoiceId(id);
    setOpenBank(true);
  };

  const handleMail = async () => {
    try {
      setLoadingMail(true);
      const access = sessionStorage.getItem("access");
      await postRequest(`invoice/generate/${invoiceId}`,
        {
          Authorization: `Bearer ${access}`,
        },
        bankDetail
      );
      handleClose();
      toast.success("Mail sent Successfully!", toastConfig);
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setTimeout(() => {
        setLoadingMail(false);
      }, 5000);
    }
  };

  const selectInvoice = (id) => {
    setUpdateId(id);
    handleOpen();
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
    fetchInvoices();
    setOpenBank(false);
    setInvoiceId(null);
    setDeleteId(null);
    setUpdateId(null);
    setBankDetail({
      note: "",
      title: "",
      details: ""
    });
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FF5722" }} />
        <Typography variant="h6" sx={{ marginTop: "18px", color: "#fff" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <div className="app-container" style={{ color: "#fff", display: "flex" }}>
      <ToastContainer />
      <SideNavBar />
      <div style={{ flex: 1, margin: "0.5rem" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          padding="1rem"
        >
          <Grid item>
            <h1>Invoices</h1>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{
                backgroundColor: "#fff",
                color: "black",
                "&:hover": {
                  backgroundColor: "#e1e1e1",
                },
              }}
            >
              Add Invoice
            </Button>
          </Grid>
        </Grid>
        {invoices.length === 0 ? (
          <Typography variant="h6" sx={{ color: "#fff", textAlign: "center" }}>
            No Data Yet
          </Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Invoice No
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Client Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Project Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice, id) => (
                  <TableRow key={id}>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {id + 1}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {invoice.invoiceNumber}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {invoice.title}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {invoice.clientName}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {invoice.projectsList.map((project, keys) => (
                        <li key={keys}>{project.projectName}</li>
                      ))}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {invoice.projectsList.map((project, keys) => (
                        <li
                          key={keys}
                        >{`${project.amount} ${invoice.currency}`}</li>
                      ))}
                      { }
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {invoice.date}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {invoice.status}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#1976D2",
                          margin: 2,
                        }}
                        aria-label="view"
                        size="small"
                        onClick={() => handleBankOpen(invoice._id)}
                      >
                        <EmailOutlinedIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#4CAF50",
                          margin: 2,
                        }}
                        aria-label="edit"
                        size="small"
                        onClick={() => selectInvoice(invoice._id)}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#F44336",
                          margin: 2,
                        }}
                        aria-label="delete"
                        size="small"
                        onClick={() => handleClickOpen(invoice._id)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Dialog
          open={open}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#212121",
              color: "#fff",
              padding: "15px",
            },
            "& .MuiDialogTitle-root": {
              padding: "5px",
            },
            "& .MuiDialogContentText-root": {
              color: "#fff",
            },
            "& .MuiDialogContent-root": {
              padding: "5px",
            },
            "& .MuiDialogActions-root": {
              padding: "5px",
            },
          }}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are You Sure You Want to Delete this Project?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: "#A6A6A6",
                color: "#fff",
                "&:hover": { backgroundColor: "#B8B8B8" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              sx={{
                backgroundColor: "#E5622D",
                color: "#fff",
                "&:hover": { backgroundColor: "#D0521B" },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="sm"
          open={openBank}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#212121",
              color: "#fff",
              padding: "20px",
              borderRadius: "8px",
            },
            "& .MuiDialogTitle-root": {
              padding: "10px 5px",
              borderBottom: "1px solid #E5622D",
            },
            "& .MuiDialogContent-root": {
              padding: "10px",
            },
            "& .MuiDialogActions-root": {
              padding: "10px 5px",
              justifyContent: "space-around",
            },
          }}
        >
          <DialogTitle
            sx={{
              typography: "h6",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "#E5622D",
            }}
          >
            Confirm Bank Details
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: "20px", color: "#fff" }}>
              Select the Bank Details for This Invoice you want to Share
            </DialogContentText>
            {bankData.length === 0 ? (
              <Typography
                variant="h6"
                sx={{ color: "#fff", textAlign: "center", margin: "10px 0" }}
              >
                No Item Assigned Yet
              </Typography>
            ) : (
              <>
                <FormControl
                  variant="outlined"
                  fullWidth
                  sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      color: "#FEFEFE",
                      "& fieldset": {
                        borderColor: "#FEFEFE",
                      },
                      "&:hover fieldset": {
                        borderColor: "#FEFEFE",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#FEFEFE",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FEFEFE",
                    },
                  }}
                >
                  <InputLabel id="bank-select-label">Account Title</InputLabel>
                  <Select
                    labelId="bank-select-label"
                    value={bankData._id}
                    onChange={handleSelectChange}
                    label="Account Title"
                  >
                    {bankData.map((bank) => (
                      <MenuItem key={bank._id} value={bank._id}>
                        Title: {bank.title} {bank.details}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!bankDetail.note}
                      onChange={(e) =>
                        setBankDetail((prev) => ({
                          ...prev,
                          note: e.target.checked
                            ? 'Note: Please add the Beneficiary name as "Muhammad Hasan Alvi" not "Burnt Brains"'
                            : "",
                        }))
                      }
                      sx={{
                        color: "#FEFEFE",
                        "&.Mui-checked": {
                          color: "#FF5722",
                        },
                      }}
                    />
                  }
                  label={
                    <span style={{ color: "#FEFEFE" }}>
                      Note: Please add the Beneficiary name as 'Muhammad Hasan Alvi' not 'Burnt Brains'
                    </span>
                  }
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              disabled={loadingMail}
              sx={{
                backgroundColor: "#A6A6A6",
                color: "#fff",
                "&:hover": { backgroundColor: "#B8B8B8" },
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={bankDetail.title === "" || loadingMail}
              onClick={handleMail}
              sx={{
                backgroundColor: "#E5622D",
                color: "#fff",
                "&:hover": { backgroundColor: "#D0521B" },
                "&.Mui-disabled": {
                  backgroundColor: "#EC916C",
                  color: "#fff",
                },
              }}
            >{loadingMail ? (
              <CircularProgress size={24} sx={{ color: "#fff" }} />
            ) : (
              "Send Mail"
            )}
            </Button>
          </DialogActions>
        </Dialog>
        <AddInvoice
          open={openModal}
          handleClose={handleClose}
          invoiceId={updateId}
        />
      </div>
    </div>
  );
};

export default Invoices;

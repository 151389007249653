import React, { useState, useEffect } from "react";
import { getRequest, patchRequest, postRequest } from "../../axiosRoutes/index";
import {
    Button,
    Modal,
    Box,
    Typography,
    OutlinedInput,
    FormControl,
    InputLabel,
    Grid,
    TextField,
    Hidden,
    CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const AddClient = ({ open, handleClose, bankId }) => {
    const [loading, setLoading] = useState(false);
    const [bankData, setBankData] = useState({
        title: "",
        details: "",
    });

    useEffect(() => {
        const fetchClients = async () => {
            if (bankId !== null) {
                const access = sessionStorage.getItem("access");
                try {
                    setLoading(true);
                    const response = await getRequest(`admin/bank/${bankId}`, {
                        headers: {
                            Authorization: `Bearer ${access}`,
                        },
                    });
                    setBankData({
                        title: response.title,
                        details: response.details,
                    });
                } catch (error) {
                    console.error("Error fetching Bank Details:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setBankData({
                    title: "",
                    details: "",
                });
            }
        };
        fetchClients();
    }, [bankId]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const access = sessionStorage.getItem("access");
            await patchRequest(
                `admin/bank/${bankId}`,
                {
                    Authorization: `Bearer ${access}`,
                },
                bankData
            );
            toast.success("Bank Details Updated Successfully", toastConfig);
            handleClose();
        } catch (error) {
            if (!error.response) {
                toast.error(error.message, toastConfig);
            } else if (error.response) {
                toast.error(error.response.data.message, toastConfig);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBankData({ ...bankData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const access = sessionStorage.getItem("access");
            await postRequest(
                "admin/bank",
                {
                    Authorization: `Bearer ${access}`,
                },
                bankData
            );
            toast.success("Bank Details Added Successfully", toastConfig);
            setBankData({
                title: "",
                details: "",
            });
            handleClose();
        } catch (error) {
            if (!error.response) {
                toast.error(error.message, toastConfig);
            } else if (error.response) {
                toast.error(error.response.data.message, toastConfig);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "40%",
                    maxHeight: "80vh",
                    overflowY: "auto",
                    bgcolor: "black",
                    color: "#FEFEFE",
                    border: "1px solid #A9A9A9",
                    p: 2,
                    "& .MuiOutlinedInput-root": {
                        color: "#FEFEFE",
                        "& fieldset": {
                            borderColor: "#FEFEFE",
                        },
                        "&:hover fieldset": {
                            borderColor: "#FEFEFE",
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "#FEFEFE",
                        },
                    },
                    "& .MuiInputLabel-root": {
                        color: "#FEFEFE",
                    },
                    "& .MuiIconButton-root": {
                        color: "#FEFEFE",
                    },
                }}
            >
                <ToastContainer />
                <Grid container spacing={2}>
                    <Hidden mdDown>
                        <Grid item md={4}>
                            <AccountBalanceIcon
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "8px",
                                    color: "#E5622D",
                                }}
                            />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={8}>
                        <Typography
                            id="modal-modal-title"
                            variant="h4"
                            fontWeight="bold"
                            textAlign="center"
                            component="h1"
                            sx={{ mb: 2 }}
                        >
                            {bankId ? "Update Bank Details" : "Add Bank Details"}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                                    <InputLabel htmlFor="outlined-adornment-title">
                                        Account Title
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-title"
                                        type="text"
                                        name="title"
                                        label="Account Title"
                                        value={bankData.title}
                                        onChange={handleChange}
                                        sx={{ backgroundColor: "transparent" }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="bank-details"
                                    label="Bank Details (Comma-Separated)"
                                    name="details"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    maxRows={10} // Optional: Limit maximum height with 10 rows
                                    value={bankData.details}
                                    onChange={handleChange}
                                    sx={{
                                        mb: 1,
                                        backgroundColor: "transparent",
                                        "& .MuiInputBase-root": {
                                            overflow: "auto", // Ensures proper scrolling if maxRows is reached
                                        },
                                    }}
                                />
                            </Grid>

                            {bankId !== null ? (
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleUpdate}
                                        sx={{
                                            bgcolor: "#E5622D",
                                            "&:hover": { bgcolor: "#D0521B" },
                                        }}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                                        ) : (
                                            "Update"
                                        )}
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleSubmit}
                                        sx={{
                                            bgcolor: "#E5622D",
                                            "&:hover": { bgcolor: "#D0521B" },
                                        }}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                                        ) : (
                                            "Add"
                                        )}
                                    </Button>
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={handleClose}
                                    sx={{ bgcolor: "#A9A9A9", "&:hover": { bgcolor: "#E1E1E1" } }}
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default AddClient;
